[data-theme='light'] {
	--background: #5d7eab;
	--shadow: rgba(0, 0, 0, 5);
	--buttons: #e2e4f6;
	--text: #e2e4f6;
	--text-primary: #5d7eab;;
	--filter: brightness(70%);
	--borders: #325778;
	--hover-text: #d7ac37;
}

[data-theme='dark'] {
	--background: #011228;
	--shadow: rgba(0, 0, 0, 5);
	--buttons: #1c375d;
	--text: #e2e4f6;
	--text-primary: #e2e4f6;
	--filter: brightness(70%);
	--borders: #172d4f;
	--hover-text: #d7ac37;
}

* {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	z-index: 0;
}

#menu {
	float: left;
	width: 100px;
	margin-top: 2px;
}

#theme {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	transition: 0.2s ease-out;
}

#theme:hover {
	cursor: pointer;
}

.related-pages-header {
    font-size: 2em;
    color: var(--text);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.banner-container {
	justify-content: center;
	display: flex;
}

.banner {
	background-color: rgb(255, 128, 128);
	padding: 20px 40px 10px 40px;
	border-radius: 0px 0px 15px 15px;
	filter: drop-shadow(3px 3px 3px);
	font-size: x-large;
	animation: banner-ease ease-in-out 2s forwards;
	text-align: center;
	cursor: default;
}

@keyframes text-bubble-bounce {
	0%,
	50%,
	100% {
		transform: translateY(335px) scale(0.95);
	}
	50% {
		transform: translateY(330px) scale(1.05);
	}
	100% {
		transform: translateY(335px) scale(1);
	}
}

#text-bubble {
	text-align: center;
	animation: text-bubble-bounce ease-in-out 0.3s forwards;
	background-color: white;
	color: black;
	padding: 5px;
	border-radius: 10px;
	border: 2px solid black;
	min-width: 100%;
	min-height: 60px;
	align-content: center;
}

#text-bubble::before {
	content: '';
	position: absolute;
	top: -39%;
	left: 10px;
	border-width: 15px;
	border-style: solid;
	border-color: transparent transparent black transparent;
}

#text-bubble::after {
	content: '';
	position: absolute;
	top: -34%;
	left: 11px;
	border-width: 14px;
	border-style: solid;
	border-color: transparent transparent white transparent;
}

#blob-container {
	position: fixed;
	bottom: 0;
	left: 0;
	text-align: left;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	cursor: pointer;
	opacity: 1;
	width: 160px;
	height: 160px;
	margin-bottom: 4%;
	margin-left: 11%;
	transition: 0.2s;
	pointer-events: none;
	z-index: 1;
}

.blob-container-algo {
	position: absolute;
	bottom: 20%;
	right: 80px;
}

#blob-container:hover {
	width: 165px;
	height: 165px;
}

.blobLogo {
	width: 100px;
	position: relative;
	top: 130px;
	left: 30px;
	z-index: 2;
	pointer-events: auto; /* Allow mouse events */
    -webkit-pointer-events: auto; /* Safari */
}

.blobLogo-animate {
	animation: blob-logo-bounce ease-in-out 0.3s forwards;
}

@keyframes blob-logo-bounce {
	0%,
	50%,
	100% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1.4);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes banner-ease {
	from {
		transform: translateY(-7vw);
	}
	to {
		transform: translateY(-25px);
	}
}

.rotate-effect {
	transition: transform 1.5s ease;
}

.rotate-effect:hover {
	transform: rotate(360deg);
}

#title {
	margin: 0 auto;
	width: 100px;
}

.gimmicks {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 25px;
}

body {
	font: 100% 'Cabin', 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: var(--background);
	color: var(--primary);
}

.about {
	padding: 5em 1em 1em 1em;
	margin-left: 30px;
}

.code {
	background: #000;
}

.menu {
	position: fixed;
	top: 68px;
	left: -240px;
	height: 100%;
	width: 220px;
	background-color: var(--background);
	opacity: 0;
	line-height: 3em;
	padding: 1.5em;
	z-index: 3;
}

.menu.show {
	left: -20px;
	opacity: 0.97;
	animation-name: enter;
	animation-duration: 0.5s;
	z-index: 3;
}

.menu.hide {
	animation-name: exit;
	animation-duration: 0.5s;
}

.menu ul {
	list-style-type: none;
}

.menu a {
	font-size: 1.4em;
	font-weight: 700;
	text-decoration: none;
	color: var(--primary);
	transition: all 0.4s ease 0s;
}

.menu a:hover {
	color: var(--hover-text);
}

.content {
	background-color: var(--background);
	transition: all 0.4s ease 0s;
}

.content h1 {
	font-size: 3em;
	text-align: center;
	/* color: #4277bb; */
}

.content h3 {
	text-align: center;
	font-weight: 100;
}

/* .content a {
	cursor: default;
} */

.about a,
.four-o-four a {
	text-decoration: underline;
	color: var(--primary);
	cursor: pointer;
	transition: all 0.4s ease 0s;
}

.about a:hover,
.four-o-four a:hover {
	color: var(--hover-text);
}

.text,
.personal {
	margin: 0px 100px 100px 200px;
	position: absolute;
}

.personal a {
	color: #5c9ba4;
	text-decoration: none;
}

.personal a:hover {
	color: #f9c333;
}

.header {
	position: fixed;
	width: 100%;
	height: 65px;
	z-index: 1;
	display: flex;
	flex-direction: row;
	font-family: 'Jost', Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.menu-bar {
	width: 35px;
	height: 35px;
	margin-top: 14px;
	margin-right: 25px;
	cursor: pointer;
	transition: 0.2s ease-out;
}

.menu-bar:hover {
	transform: scale(1.15);
	transition: 0.4s ease-in;
}

div.header h1 {
	position: absolute;
	top: 50%;
	left: 50vw;
	transform: translate(-50%, -50%);
	margin: 0;
	white-space: nowrap;
}

div.container {
	width: 100%;
	margin: 0px;
	/* Makes the container take up 100% of the device height even if the page has minimal content */
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
}

div.header {
	background-color: var(--background);
	color: var(--text);
	text-align: center;
	border-bottom: 1.5px var(--borders) solid;
}

div.footer {
	color: var(--text-primary);
	background: var(--buttons);
	text-align: center;
	border-top: 1.5px var(--borders) solid;
	border-bottom: 1.5px var(--borders);
}

.footer {
	padding: 10px;
	z-index: -1;
}

.footer a {
	color: var(--text-primary);
}

div.content {
	padding: 5em 1em 1em 1em;
	/* grows the content to fill extra space in the container */
	flex: 1;
}

.contact {
	text-align: center;
	margin: 25px 0px 0px 400px;
	position: absolute;
}

.inner-flex button.button {
	display: flex;
	justify-content: space-between;
	font-family: 'IBM Plex Sans Condensed', Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 1.4em;
	font-weight: 700;
	color: var(--text-primary);
	background: var(--buttons);
	width: 85%;
	height: 80%;
	padding: 5px;
	margin: 5% 5% 5% 15%;
	cursor: pointer;
	border: 1.5px var(--borders) solid;
	border-radius: 40px;
	box-shadow: 5px 5px 12px var(--shadow);
	transition: all 0.4s ease 0s;
	pointer-events: auto;
}

.algo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
	text-align: center;
    width: 100%;
	padding: 5%;
}

.algo-name {
    width: 100%;
	padding-bottom: 0px;
    display: flex;
    justify-content: center;
}

.algo-picture {
	width: 80%;
	padding: 5%;
}
.algo-picture img {
	width: 100%;
}

.pseudocode-icon {
	display: flex;
	align-items: center;
	margin-top: -4px;
	margin-bottom: -4px;
}

.inner-flex button.button:hover,
.inner-flex button.button:active {
	filter: var(--filter);
}

.inner-flex button.button:focus,
.inner-flex a:focus {
	outline: none;
}

.inner-flex button.button::-moz-focus-inner,
.inner-flex a::-moz-focus-inner {
	border: 0;
}

.outer-flex {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	pointer-events: none;
	margin-left: 3%;
	margin-right: 3%;
}

.inner-flex {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.mid-flex {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.divider {
	width: 100%;
	text-align: center;
	font-size: 1.5em;
	margin: 20px 5px 20px 5px;
	white-space: nowrap;
}

.divider span {
	background-color: #b0b3b8;
	color: white;
	border-radius: 15px;
	padding: 5px 10px 5px 10px;
	box-shadow: 0px 8px 15px rgba(255, 137, 137, 0.5);
}

.dsa-filter {
	width: 90%;
	display: block;
	margin: 5% auto;
	font-size: 1.2em;
	padding: 0.8rem 1rem;
	border-radius: 40px;
	background-color: var(--background);
	color: var(--buttons);
}

input::placeholder {
	color: var(--text);
}

.no-results {
	display: block;
	margin: 1rem auto;
	font-size: 1.5em;
	color: white;
}

.side-panel-cont {
	width: 100%;
	z-index: 2;
	margin-top: 30px;
}

.side-panel {
    position: sticky;
    top: 100;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    background: var(--buttons);
    min-width: 24%;
    padding: 5px;
    padding-bottom: 15px;
    border: 1.5px var(--borders) solid;
    border-radius: 40px;
    box-shadow: 5px 12px 12px var(--shadow);
    transition: all 0.4s ease 0s;
    pointer-events: auto;
    z-index: 2;
}

.Side-Buttons button {
	display: block;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-family: 'IBM Plex Sans Condensed', Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
	color: var(--text-primary);
	font-size: 24px;
	margin: auto;
	padding: 2.5%;
	background-color: var(--buttons);
	border: var(--buttons);
}

.Side-Buttons button:hover {
	cursor: pointer;
	color: var(--hover-text);
	background-color: var(--buttons);
	border: var(--buttons);
}

@media screen and (min-width: 1280px) {
	.content a {
		width: 33.3%;
	}
}

@media screen and (min-width: 700px) and (max-width: 1280px) {
	.side-panel {
		width: 33.3%;
	}

	.content a {
		width: 50%;
	}
}

@media screen and (max-width: 700px) {
	.inner-flex {
		width: 100%;
	}

	.side-panel {
		width: 100%;
	}

	.content a {
		width: 100%;
	}
}

@media screen and (min-width: 1000px) {
	.header h1 {
		font-size: 32px;
		font-weight: 500;
	}
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
	.header h1 {
		font-size: 3vw;
	}
}

@media screen and (max-width: 600px) {
	.header h1 {
		display: none;
	}
}

@keyframes enter {
	from {
		left: -240px;
		opacity: 0;
	}
	to {
		left: -20px;
		opacity: 0.97;
	}
}

@keyframes exit {
	from {
		left: -20px;
		opacity: 0.97;
	}
	to {
		left: -240px;
		opacity: 0;
	}
}